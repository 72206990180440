<template>
  <LazyImage
    :computedTo="to ? to : prismicTo"
    :computedHref="href ? href : prismicHref"
    :computedIsInternal="isInternal"
    :src="src"
    :retinaSrc="retinaSrc"
    :mobileSrc="mobileSrc"
    :mobileRetinaSrc="mobileRetinaSrc"
    :width="width"
    :height="height"
    :alt="computedAlt"
    :disableLazy="disableLazy"
  />
</template>

<script>
  import prismicLinkMixin from '@/mixins/prismicLinkMixin';

  export default {
    name: 'ShopifyLazyImage',

    mixins: [ prismicLinkMixin ],

    components: {
      LazyImage: () => import('@/components/global/LazyImage')
    },

    props: {
      image: {
        type: Object,
        default: null,
      },
      width: {
        type: [String, Number],
        default: null,
      },
      height: {
        type: [String, Number],
        default: null,
      },
      mobileWidth: {
        type: [String, Number],
        default: 600,
      },
      alt: {
        type: String,
        default: null
      },
      disableLazy: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      aspectRatio() {
        if (!this.width || !this.height) return 1;

        return (this.height / this.width);
      },
      mobileHeight() {
        return parseInt(this.mobileWidth) * this.aspectRatio;
      },
      src() {
        if(this.image) {
          return this.getImageSrc('normal', this.width, this.height);
        }
      },
      retinaSrc() {
        if(this.image) {
          return this.getRetinaSrc(this.width, this.height);
        }
      },
      mobileSrc() {
        if(this.image) {
          if (this.width > this.mobileWidth) {
            return this.getImageSrc('normal', this.mobileWidth, this.mobileHeight);
          } else {
            return this.src;
          }
        }
      },
      mobileRetinaSrc() {
        if(this.image) {
          if (this.width > this.mobileWidth) {
            return this.getRetinaSrc(this.mobileWidth, this.mobileHeight);
          } else {
            return this.retinaSrc;
          }
        }
      },
      computedAlt() {
        if(this.alt) {
          return this.alt;
        }

        if(this.image) {
          return this.image.alt;
        }
      }
    },

    methods: {
      buildImageSrc(type, width, height, extension) {
        const pixelDensity = type == 'retina' ? '@2x' : '';

        if(width && height) {
          return `_${width}x${height}${pixelDensity}.${extension}`;
        }

        if(width) {
          return `_${width}x${pixelDensity}.${extension}`;
        }

        if(height) {
          return `_x${height}${pixelDensity}.${extension}`;
        }
      },
      getImageSrc(type, width, height) {
        const jpg = this.buildImageSrc(type, width, height, 'jpg');
        const jpeg = this.buildImageSrc(type, width, height, 'jpeg');
        const png = this.buildImageSrc(type, width, height, 'png');

        return this.image.src.replace('.jpg', jpg).replace('.jpeg', jpeg).replace('.png', png);
      },
      getRetinaSrc(width, height) {
        const widthNum = parseInt(width);
        const heightNum = parseInt(height);

        let isImageLargeEnough;

        if(widthNum && heightNum) {
          isImageLargeEnough = this.image.height >= heightNum || this.image.width >= widthNum;
        }

        if(widthNum) {
          isImageLargeEnough = this.image.width >= widthNum;
        }

        if(heightNum) {
          isImageLargeEnough = this.image.height >= heightNum;
        }

        if(isImageLargeEnough) {
          return this.getImageSrc('retina', widthNum, heightNum);
        }
      }
    }
  };
</script>
