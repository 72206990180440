<template>
  <button class="product">
    <ShopifyLazyImage
      v-if="featuredImage"
      :image="featuredImage"
      width="60"
      height="60"
    />
  </button>
</template>

<script>
  import productMixin from '@/mixins/productMixin';

  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage';

  export default {
    name: 'QuickShopCarouselImage',

    mixins: [ productMixin ],

    components: {
      ShopifyLazyImage
    },

    props: {
      product: {
        type: Object,
        default: null
      }
    }
  }
</script>
