<template>
  <section
    class="page-title"
    :class="{ 'page-title--has-image': model && model.node.featured_image }"
  >
    <div class="row">
      <div
        class="column small-12"
        :class="{ 'large-6': model && model.node.featured_image }"
      >
        <Breadcrumbs v-if="breadcrumbs" :crumbs="breadcrumbs" />

        <Tooltip v-if="siblings" :isArrowDropdown="true">
          <template v-slot:trigger>
            <span class="h1">{{
              model && model.node && model.node.title ? $prismic.asText(model.node.title) : title
            }}</span>
          </template>

          <template v-slot:content>
            <ul class="page-title__sibling-menu">
              <li v-for="(item, i) in siblings" :key="i">
                <PrismicLink :link="item" :text="$prismic.asText(item.title)" />
              </li>
            </ul>
          </template>
        </Tooltip>

        <template v-else>
          <span class="h1">{{
            model && model.node && model.node.title ? $prismic.asText(model.node.title) : title
          }}</span>
          <p class="desc" v-if="model">
            {{ model.node.description }}
          </p>
        </template>

        <div v-if="children" class="page-title__categories">
          <span class="h6">Categories:</span>
          <template v-for="(item, i) in children">
            <PrismicLink
              :key="i"
              :link="item"
              :text="$prismic.asText(item.title)"
            />{{ i !== children.length - 1 ? ", " : "" }}
          </template>
          <!-- <ul>
                <li v-for="(item, i) in children" :key="i">
                    <PrismicLink
                        :link="item"
                        :text="$prismic.asText(item.title)" />
                </li>
            </ul> -->
        </div>

        <p v-if="siblings && model" class="desc">
          {{ model.node.description }}
        </p>
      </div>

      <div v-if="model" class="column small-12 large-6">
        <PrismicLazyImage
          v-if="model.node.featured_image"
          :image="model.node.featured_image"
          width="960"
          height="540"
          :disableLazy="true"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingTop",

  components: {
    Tooltip: () => import("@/components/global/Tooltip"),
    PrismicLink: () => import("@/components/prismic/PrismicLink"),
    Breadcrumbs: () => import("@/components/pages/shop-landing/Breadcrumbs"),
    PrismicLazyImage: () => import("@/components/prismic/PrismicLazyImage")
  },

  props: {
    model: {
      // the actual page data that we want to show.
      type: Object,
      default: () => null
    },
    title: String,
    breadcrumbs: Array,
    children: Array,
    siblings: Array
  }
};
</script>
