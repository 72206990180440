<template>
  <section class="collection large-pad">
    <div class="row">
      <div class="collection__left column small-12 xlarge-4 xxlarge-3">
        <div class="row">
          <div class="column small-12 large-8 xlarge-12">
            <h2 class="h5">{{ computedTitle }}</h2>
            <template v-if="subtitle">
              <h3 class="h1" v-if="subtitle[0].type">
                <prismic-rich-text :field="subtitle" />
              </h3>
              <h3 v-else class="h1" v-html="subtitle"></h3>
            </template>

            <template v-if="description">
              <prismic-rich-text
                v-if="description[0].type"
                :field="description"
              />
              <p v-else-if="description">{{ description }}</p>
            </template>
          </div>

          <div class="column small-12 large-4 xlarge-12">
            <ArrowLink v-if="to" :to="to" text="Shop All" />
            <slot></slot>
          </div>
        </div>
      </div>

      <div
        v-if="prods.length"
        class="collection__right column small-12 xlarge-8 xxlarge-9"
      >
        <div class="product-slider">
          <ClientOnly>
            <VueSlickCarousel
              v-bind="sliderSettings"
              ref="slider"
              :class="`has-${prods.length}`"
            >
              <ProductCard
                v-for="(prod, i) in prods"
                :key="i"
                :product="prod"
                :hidePrice="true"
                :modalTitle="computedTitle"
                :modalProducts="prods"
              />

              <template #prevArrow="arrowOption" v-if="!hideArrows">
                <button class="button">
                  <SvgIcon name="chevron-left-light" title="Previous" />
                </button>
              </template>

              <template #nextArrow="arrowOption" v-if="!hideArrows">
                <button class="button">
                  <SvgIcon name="chevron-right-light" title="Next" />
                </button>
              </template>
            </VueSlickCarousel>
          </ClientOnly>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { removeOtherLocaleProducts } from "@/helpers";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import SvgIcon from "@/components/global/SvgIcon";
import LazyImage from "@/components/global/LazyImage";
import ArrowLink from "@/components/global/ArrowLink";
import ProductCard from "@/components/shop/ProductCard";

export default {
  name: "Collection",

  components: {
    LazyImage,
    SvgIcon,
    ArrowLink,
    ProductCard,
    VueSlickCarousel
  },

  data() {
    return {
      hideArrows: false,
      sliderSettings: {
        infinite: false,
        slidesToShow: 3,
        // focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              centerMode: true,
              centerPadding: '33.333%'
            }
          }
        ]
      }
    };
  },

  props: {
    subtitle: {
      type: [String, Array],
      default: "Collection"
    },
    title: {
      type: String,
      default: null
    },
    to: {
      type: String,
      default: null
    },
    description: {
      type: [String, Array],
      default: null
    },
    products: {
      type: Array,
      default: () => null
    }
  },

  computed: {
    computedTitle() {
      return this.title ? this.title : "Collection";
    },

    prods() {
      if (!this.products) return [];

      const products = removeOtherLocaleProducts(
        this.products.map(prod => prod.product ?? prod).filter(v => v !== null),
        this.$store.state.locale
      );

      // If there's a shop all link, only show up to 10 products 
      if (this.to) {
        return products.slice(0, 10);
      } else {
        return products;
      }
    }
  },

  watch: {
    products() {
      this.sliderSettings.slidesToShow = 1;
      this.hideArrows = true;

      setTimeout(() => {
        this.sliderSettings.slidesToShow = 3;
        this.hideArrows = false;
      }, 1);
    }
  }
};
</script>
