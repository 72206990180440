<template>
  <div class="icons">
    <SvgIcon v-if="isDay" name="day-small" />
    <SvgIcon v-if="isNight" name="night-small" />
  </div>
</template>

<script>
  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'Icons',

    components: {
      SvgIcon
    },

    props: {
      isDay: {
        type: Boolean,
        default: false
      },
      isNight: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
