<template>
  <div class="row shop-landing__item">
    <div class="column" ref="stickyParent">
      <h3><router-link :to="url">{{ title }}</router-link></h3>
    </div>
    <div class="column shop-landing__item__content">
      <p v-if="item.description">{{ item.description }}</p>
      <ArrowLink :to="url" :text="this.type == 'concern' ? title + ' Edit' : 'View ' + title" />
    </div>
  </div>
</template>

<script>
  import { getTitle } from '@/helpers';

  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'LandingItem',

    components: {
      ArrowLink
    },

    props: {
      item: {
        type: Object,
        default: null
      },
      type: {
        type: String,
        default: null
      }
    },

    computed: {
      title() {
        return getTitle(this.item.title);
      },
      url() {
        return '/shop/' + this.type + 's/' + this.item._meta.uid;
      }
    }
  }
</script>
