<template>
  <span>${{ (salePrice ? salePrice : price) | currency }} <strike v-if="salePrice && (salePrice !== price)">${{ price | currency }}</strike></span>
</template>

<script>
  export default {
    name: 'Price',

    props: {
      price: {
        type: [String, Number],
        default: null
      },
      salePrice: {
        type: [String, Number],
        default: null
      }
    }
  }
</script>
