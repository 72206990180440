<template>
  <div v-if="singleSize || (sizes.length && value)" class="form__row size-picker" :class="type ? 'size-picker--' + type : ''">
    <div class="form__field">
      <span v-if="singleSize || sizes.length == 1" :class="{ 'single-size' : singleSize }">
        {{ singleSize ? singleSize : sizes[0].name }}
      </span>

      <template v-if="sizes.length > 1">
        <fieldset>
          <legend>{{ label }}</legend>
          <template v-for="(size, index) in sizes" v-if="size.visible">
            <input
              :id="size.name + (type ? '-' + type : '')"
              type="radio"
              :value="size.name"
              :name="'size-picker' + (type ? '-' + type : '')"
              v-model="value.selected"
              :class="[{ 'unavailable' : !size.available }, { 'coming-soon' : size.comingSoon }]"
            >
            <label :for="size.name + (type ? '-' + type : '')">{{ size.name }}</label>
          </template>
        </fieldset>
      </template>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProductSizePicker',

    props: {
      type: {
        type: String,
        default: null
      },

      sizes: {
        type: Array,
        default: () => ([])
      },

      label: {
        type: String,
        default: 'Size'
      },

      singleSize: {
        type: String,
        default: null
      },

      value: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>
