<template>
  <nuxt-link v-if="!isBundle" :to="`/shop/brands/${brand._meta.uid}`" class="brand">
    <div>{{ $prismic.asText(brand.title) }}</div>
  </nuxt-link>
  <span v-else class="brand">
    <div>Bundle</div>
  </span>
</template>

<script>
  export default {
    name: 'BrandLink',

    props: {
      brand: {
        type: Object,
        default: {},
      },
      isBundle: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
