<template>
  <section v-if="about" class="about-the-gees">
    <div class="row">
      <div class="about-the-gees__left column small-12 xlarge-4">
        <div class="stacked-images">
          <div class="back-image">
            <PrismicLazyImage
              :image="about.about_the_gees_image_1"
              width="500"
              height="500"
            />
          </div>
          <div class="front-image">
            <PrismicLazyImage
              :image="about.about_the_gees_image_2"
              width="390"
              height="390"
            />
          </div>
        </div>
        <div class="stacked-images stacked-images--reverse">
          <div class="front-image">
            <PrismicLazyImage
              :image="about.about_the_gees_image_3"
              width="390"
              height="390"
            />
          </div>
          <div class="back-image">
            <PrismicLazyImage
              :image="about.about_the_gees_image_4"
              width="500"
              height="500"
            />
          </div>
        </div>
      </div>
      <div class="about-the-gees__center column small-12 medium-10 large-8 xlarge-4">
        <h2 class="h5">{{ about.about_the_gees_title | getTitle }}</h2>
        <h3 v-if="about.about_the_gees_subtitle" class="h1"><prismic-rich-text :field="about.about_the_gees_subtitle" /></h3>
        <p v-if="about.about_the_gees_description">{{ about.about_the_gees_description }}</p>
        <ArrowLink :link="about.about_the_gees_link" text="Learn More" />
      </div>
      <div class="about-the-gees__right column small-12 xlarge-4">
        <div class="stacked-images stacked-images--reverse">
          <div class="back-image">
            <PrismicLazyImage
              :image="about.about_the_gees_image_3"
              width="390"
              height="390"
            />
          </div>
          <div class="front-image">
            <PrismicLazyImage
              :image="about.about_the_gees_image_4"
              width="500"
              height="500"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { fetchAboutTheGees } from '@/prismic/queries';

  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'AboutTheGees',

    components: {
      PrismicLazyImage,
      ArrowLink
    },

    data() {
      return {
        about: null
      }
    },

    async fetch() {
      try {
        const { data } = await this.$apolloProvider.defaultClient.query({
          query: fetchAboutTheGees
		     });

        const response = data.allGlobals.edges;

        if(response.length) {
          this.about = response[0].node;
        }
      } catch(err) {
        console.log(err);
      }
    }
  }
</script>
