import { store } from './store'

export function prettify(string) {
  var words = string.match(/([^-]+)/g) || []
  words.forEach(function (word, i) {
    words[i] = word[0].toUpperCase() + word.slice(1)
  })
  return words.join(' ')
}

export function slugify(string) {
  string = string.replace(/^\s+|\s+$/g, '') // trim
  string = string.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  var to = 'aaaaeeeeiiiioooouuuunc------'
  for (var i = 0, l = from.length; i < l; i++) {
    string = string.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  string = string
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return string
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function date(string) {
  const d = new Date(string)
  const year = d.getFullYear()
  const month = d.getMonth()
  const date = d.getDate()

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return `${months[month]} ${date}, ${year}`
}

export function dateTime(string) {
  const d = new Date(string)
  const year = d.getFullYear()
  const month = d.getMonth()
  const date = d.getDate()
  const militaryHour = d.getHours()
  const min = d.getMinutes()
  const amPm = militaryHour < 12 ? 'am' : 'pm'
  const hour = militaryHour > 12 ? militaryHour - 12 : militaryHour
  const mins = min < 10 ? '0' + min : min

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return `${months[month]} ${date}, ${year} at ${hour}:${mins} ${amPm}`
}

export function currency(string) {
  return parseFloat(string).toFixed(2)
}

export function truncate(str, max, suffix = '...') {
  return str.length < max
    ? str
    : `${str.substr(
        0,
        str.substr(0, max - suffix.length).lastIndexOf(' ')
      )}${suffix}`
}

export const getTitle = value => {
  if (typeof value === 'string') return value

  if (value) return value[0] ? value[0].text : null
}

export const getAllServiceCategories = studio => {
  if (studio) {
    let serviceCategories = []

    studio.services.forEach(service => {
      serviceCategories.push(service.service_category)
    })

    return serviceCategories.filter((category, index) => {
      const _category = JSON.stringify(category)
      return (
        index ===
        serviceCategories.findIndex(obj => {
          return JSON.stringify(obj) === _category
        })
      )
    })
  }
}

export function fromNow(date) {
  var unixDate = new Date(date).getTime()

  var seconds = Math.floor((new Date() - unixDate) / 1000)
  var years = Math.floor(seconds / 31536000)
  var months = Math.floor(seconds / 2592000)
  var days = Math.floor(seconds / 86400)

  if (days > 548) {
    return years + ' years ago'
  }
  if (days >= 320 && days <= 547) {
    return 'a year ago'
  }
  if (days >= 45 && days <= 319) {
    return months + ' months ago'
  }
  if (days >= 26 && days <= 45) {
    return 'a month ago'
  }

  var hours = Math.floor(seconds / 3600)

  if (hours >= 36 && days <= 25) {
    return days + ' days ago'
  }
  if (hours >= 22 && hours <= 35) {
    return 'a day ago'
  }

  var minutes = Math.floor(seconds / 60)

  if (minutes >= 90 && hours <= 21) {
    return hours + ' hours ago'
  }
  if (minutes >= 45 && minutes <= 89) {
    return 'an hour ago'
  }
  if (seconds >= 90 && minutes <= 44) {
    return minutes + ' minutes ago'
  }
  if (seconds >= 45 && seconds <= 89) {
    return 'a minute ago'
  }
  if (seconds >= 0 && seconds <= 45) {
    return 'a few seconds ago'
  }
}

export function boldWord(string, word) {
  const n = string.toUpperCase()
  const q = word.toUpperCase()
  const x = n.indexOf(q)

  if (!q || x === -1) {
    return string // bail early
  }

  const l = q.length

  return (
    string.substr(0, x) +
    '<mark>' +
    string.substr(x, l) +
    '</mark>' +
    string.substr(x + l)
  )
}

export function shuffleArray(array) {
  if (array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))

      ;[array[i], array[j]] = [array[j], array[i]]
    }
  }

  return array
}

export function getFrequencyOptions() {
  const options = {}

  for (let i = 1; i <= 3; i++) {
    options[i + (i > 1 ? '-months' : '-month')] =
      i + (i > 1 ? ' Months' : ' Month')
  }

  return options
}

export function removeOtherLocaleProducts(products, currentLocale = 'en-ca') {
  if (!products) return null

  const shortLocale = currentLocale.replace('en-', '')

  return products.filter(product => {
    if (!product) return false

    const prod = product.node ? product.node : product

    if (prod['is_draft']) return null

    if (prod[`is_unavailable_${currentLocale === 'en-ca' ? 'ca' : 'us'}`])
      return null

    return prod[`shopify_product_${shortLocale}`]
  })
}

export function getShopifyProduct(product, locale) {
  if (!product) return null

  return locale === 'en-ca'
    ? product.shopify_product_ca
    : product.shopify_product_us
}

export const formatDays = days => {
  if (days === 'M') return 'Mo'

  if (days === 'T - TH') return 'Tu-Th'

  if (days === 'F') return 'Fr'

  if (days === 'S') return 'Sa'

  if (days === 'SU') return 'Su'
}

export const formatMilitaryTime = time => {
  if (time.includes('AM')) {
    const hour = time.replace('AM', '')
    return hour.length > 1 ? `${hour}:00` : `0${hour}:00`
  }

  if (time.includes('PM')) {
    const hour = parseInt(time.replace('PM', '')) + 12
    return `${hour}:00`
  }

  return time
}

export const sanitizeShopifyId = (resource, id) => {
  if (['Customer', 'Product', 'ProductVariant'].includes(resource)) {
    try {
      return atob(id).replace(`gid://shopify/${resource}/`, '')
    } catch {
      return Buffer.from(id, 'base64')
        .toString()
        .replace(`gid://shopify/${resource}/`, '')
    }
  }

  try {
    return atob(id).split('/').pop()
  } catch {
    return Buffer.from(id, 'base64').toString().split('/').pop()
  }
}
