<template>
  <div class="review">
    <div class="row">
      <div class="column small-12 large-3">
        <div>
          <span class="name">{{ review.user.display_name }}</span>
          <span class="time">{{ review.created_at | date }}</span>
        </div>
        <Stars :numStars="review.score" />
      </div>
      <div class="column small-12 large-9">
        <Stars :numStars="review.score" />
        <h4>{{ review.title }}</h4>
        <div class="wysiwyg">
          <p v-html="review.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Stars from '@/components/global/Stars'

  export default {
    name: 'Review',

    components: {
      Stars,
    },

    props: {
      review: {
        type: Object,
        default: null,
      },
    },
  }
</script>
