<template>
  <Modal
    v-if="activeProductUid"
    type="quick-shop"
    :class="'is-' + type"
  >
    <template v-if="title" v-slot:title>
      <span>{{ type == "search" ? "Search" : "Shopping" }}</span>
      <h2>{{ title }}</h2>
    </template>

    <template v-slot:body>
      <div
        v-if="!isSingleProduct"
        class="modal__slider"
        :class="{ 'no-slider': !isSlider }"
      >
        <div class="title">
          <h2>{{ title }}</h2>
        </div>
        <VueSlickCarousel
          v-bind="sliderSettings"
          @init="setInitialSlide"
          @beforeChange="changeActiveProductIndex"
        >
          <QuickShopCarouselImage
            v-for="product in products"
            :product="product"
            :key="product._meta.uid"
          />

          <template #prevArrow="arrowOption">
            <button>
              <SvgIcon name="chevron-up" title="Previous" />
            </button>
          </template>

          <template #nextArrow="arrowOption">
            <button>
              <SvgIcon name="chevron-down" title="Next" />
            </button>
          </template>
        </VueSlickCarousel>
      </div>

      <div class="modal__product">
        <template v-for="(product, index) in products">
          <transition :key="index">
            <QuickShopProduct
              v-if="index == activeProductUidIndex"
              :product="product"
              :quickShopVariantId="activeProductVariantId"
              :key="product._meta.id"
            />
          </transition>
        </template>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapState, mapActions } from "vuex";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import Modal from "@/components/global/Modal";
import LazyImage from "@/components/global/LazyImage";
import SvgIcon from "@/components/global/SvgIcon";
import QuickShopCarouselImage from "@/components/shop/QuickShopCarouselImage";
import QuickShopProduct from "@/components/shop/QuickShopProduct";

export default {
  name: "QuickShopModal",

  components: {
    Modal,
    VueSlickCarousel,
    LazyImage,
    SvgIcon,
    QuickShopCarouselImage,
    QuickShopProduct
  },

  data() {
    return {
      sliderSettings: {
        initialSlide: 1,
        infinite: true,
        centerMode: true,
        centerPadding: "0",
        slidesToShow: 5,
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              centerMode: false,
              vertical: false,
              verticalSwiping: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              centerMode: false,
              vertical: false,
              verticalSwiping: false
            }
          }
        ]
      }
    };
  },

  computed: {
    ...mapState({
      activeProductUid: state => state.modal.quickShop.activeProductUid,
      activeProductVariantId: state =>
        state.modal.quickShop.activeProductVariantId,
      type: state => state.modal.quickShop.type,
      title: state => state.modal.quickShop.modalTitle,
      products: state => state.modal.quickShop.modalProducts
    }),
    isSingleProduct() {
      return this.products.length == 1;
    },
    isSlider() {
      return this.products.length > 5;
    },
    activeProductUidIndex() {
      return this.products.findIndex(product => {
        return product._meta.uid == this.activeProductUid;
      });
    }
  },

  methods: {
    ...mapActions([
      "changeQuickShopModalActiveProductId",
      "changeQuickShopActiveProduct,"
    ]),

    setInitialSlide() {
      this.sliderSettings.initialSlide = this.activeProductUidIndex;
    },

    findVariantId(product) {
      const { variantSku } = product;
      const shopifyProd =
        this.$store.state.locale === "en-ca"
          ? product.shopify_product_ca
          : product.shopify_product_ca;

      const { variants } = shopifyProd;

      const variantBySku = variants.find(variant => variant.sku === variantSku);

      return variantBySku.id;
    },

    changeActiveProductIndex(oldSlideIndex, newSlideIndex) {
      const newActiveProduct = this.products[newSlideIndex];

      this.$store.dispatch("changeQuickShopActiveProduct", {
        uid: newActiveProduct._meta.uid,
        variantId: newActiveProduct.variantId ?? null
      });
    }
  }
};
</script>
