<template>
  <div class="quantity" :class="{ 'quantity--has-remove' : product }">
    <button v-if="product" class="remove" @click="removeItemFromCart(product)">
      <SvgIcon name="close" title="Remove" />
    </button>
    <label :for="'quantity-' + index" class="accessible">Quantity</label>
    <select :id="'quantity-' + index" v-model.number="value" :disabled="disabled" @change="handleChange">
      <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
    </select>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'Quantity',

    components: {
      SvgIcon
    },

    props: {
      index: {
        type: [Number, String],
        default: 1
      },
      value: {
        type: Number,
        default: 1
      },
      product: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      ...mapActions([
        'removeItemFromCart',
        'updateItemQuantity'
      ]),
      handleChange() {
        if(this.product) {
          this.updateItemQuantity({
            type: 'update',
            product: this.product,
            quantity: this.value
          });
        }
      }
    },

    watch: {
      value: {
        handler(newVal) {
          this.$emit('input', newVal)
        },
        deep: true
      }
    }
  }
</script>
