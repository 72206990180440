<template>
  <div
    v-if="text"
    class="product-tag"
    :class="{ 'product-tag--best-seller': text === 'Best Seller' }"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "ProductTag",

  props: {
    text: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.product-tag--best-seller {
  background-color: black;
  color: white;
}
</style>
