<template>
  <a v-if="reviews.isReady && reviews.totalNum" href="#reviews" v-smooth-scroll="{ offset: -100 }" class="reviews-overview" @click="handleClick">
    Read Reviews <span>({{ reviews.totalNum }})</span>
  </a>
</template>

<script>
  export default {
    name: 'ReviewsOverview',

    props: {
      reviews: {
        type: Object,
        default: null
      }
    },

    methods: {
      handleClick() {
        setTimeout(() => {
          this.$emit('click');
        }, 650);
      }
    }
  }
</script>
