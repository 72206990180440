<template>
  <div v-if="shades.length && value" class="form__row shade-picker" :class="type ? 'shade-picker--' + type : ''">
    <div class="form__field">
      <div class="shade-picker__title">
        <span class="label">{{ isKit ? 'Kit' : 'Shade Picker' }}:</span> {{ currentShade }}
      </div>
      <fieldset>
        <legend class="accessible">Select Shade</legend>
        <template v-for="shade in shades" v-if="type == 'scroll' ? currentShade == shade.name : true && shade.visible">
          <input
            :id="shade.name + (type ? '-' + type : '') + '-' + index"
            type="radio"
            :value="shade.name"
            :name="'shade-picker' + (type ? '-' + type : '') + '-' + index"
            v-model="value.selected"
            :class="[{ 'unavailable' : !shade.available }, { 'coming-soon' : shade.comingSoon }]"
          >
          <label
            :for="shade.name + (type ? '-' + type : '') + '-' + index"
            @mouseover="!isDevice && (value.hovered = shade.name)"
            @mouseleave="!isDevice && (value.hovered = null)"
          >
            <span :style="{ backgroundColor: shade.hexCode }"></span><span class="accessible">{{ shade.name ? shade.name : '' | prettify }}</span>
          </label>
        </template>
      </fieldset>
    </div>
  </div>
</template>

<script>
  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage';

  export default {
    name: 'ProductShadePicker',

    components: {
      ShopifyLazyImage
    },

    props: {
      type: {
        type: String,
        default: null
      },
      shades: {
        type: Array,
        default: () => ([])
      },
      value: {
        type: Object,
        default: () => ({})
      },
      isKit: {
        type: Boolean,
        default: false
      },
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      currentShade() {
        return this.value.hovered ? this.value.hovered : this.value.selected;
      },
      isDevice() {
        return process.client
          ? 'ontouchstart' in window ||
              navigator.maxTouchPoints > 0 ||
              navigator.msMaxTouchPoints > 0
          : false
      }
    }
  }
</script>
