<template>
  <div v-if="product" class="product">
    <div class="row">
      <div class="column small-12 large-6 xlarge-7">
        <div class="modal__product__image">
          <ShopifyLazyImage
            v-if="selectedVariant"
            :link="selectedVariantLink ? null : product"
            :to="selectedVariantLink ? selectedVariantLink : null"
            :image="selectedVariant.image"
            width="475"
            :alt="selectedVariant.title"
          />
          <ShopifyLazyImage
            v-else-if="featuredImage"
            :link="selectedVariantLink ? null : product"
            :to="selectedVariantLink ? selectedVariantLink : null"
            :image="featuredImage"
            width="475"
          />
        </div>
      </div>

      <div
        class="modal__product__info column small-12 large-6 xlarge-5 xxlarge-4"
      >
        <ProductTag :text="tagText" />
        <Seal v-if="isSkincare" />

        <div class="brand" :class="{ 'has-icons': hasIcons }">
          <BrandLink v-if="product.brand || isBundle" :brand="product.brand" :isBundle="isBundle" />
          <Icons
            v-if="hasIcons"
            :isDay="productData.is_am"
            :isNight="productData.is_pm"
          />
        </div>

        <div class="title" :class="{ 'has-icons': hasIcons }">
          <nuxt-link v-if="selectedVariantLink" :to="selectedVariantLink">
            <div>{{ $prismic.asText(product.title) }}</div>

            <Icons
              v-if="hasIcons"
              :isDay="productData.is_am"
              :isNight="productData.is_pm"
            />
          </nuxt-link>

          <PrismicLink v-else :link="product">
            <div>{{ $prismic.asText(product.title) }}</div>

            <Icons
              v-if="hasIcons"
              :isDay="productData.is_am"
              :isNight="productData.is_pm"
            />
          </PrismicLink>
        </div>

        <template v-if="!isBundle">
          <form v-if="isShoppingEnabled" class="form" @submit.prevent>
            <ProductSizePicker :sizes="sizes" :label="sizeLabel" :singleSize="product.size" v-model="selections.size" />

            <ProductShadePicker :shades="shades" v-model="selections.shade" :isKit="isKit" />

            <div v-if="prices.subscription" class="form__row">
              <div class="form__field">
                <Frequency :prices="prices" :salePrices="salePrices" v-model="selections.frequency" />
              </div>
            </div>

            <div class="form__row">
              <div class="form__field">
                <button
                  v-if="product.brand && product.brand.login_to_view_pricing_on_us && locale == 'en-us' && !isLoggedIn"
                  class="button"
                  @click="openHeaderMenuExpand('login')">
                  Log In to view pricing
                </button>
                <Button v-else-if="isPreOrder" :link="product" text="Select Options" :isFullWidth="true" />
                <AddToBag
                  v-else
                  v-model="selections.quantity.selected"
                  :product="selectedProduct"
                  :overrideLink="addToBagOverrideLink"
                  :overrideText="addToBagOverrideText"
                  :defaultPrice="defaultPrice"
                  :defaultSalePrice="defaultSalePrice"
                  :isSoldOut="isSoldOut || isSelectedVariantSoldOut"
                  :isUnavailable="isUnavailable"
                  :isComingSoon="isComingSoon || isSelectedVariantComingSoon"
                />
              </div>
            </div>
          </form>
        </template>
        <Button v-if="isBundle" :link="product" text="Select Options" :isFullWidth="true" />

        <div class="modal__product__more">
          <ArrowLink
            :link="selectedVariantLink ? null : product"
            :to="selectedVariantLink ? selectedVariantLink : null"
            text="Learn More"
          />

          <CopyLink :link="copyLink" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

import productMixin from "@/mixins/productMixin";

import ShopifyLazyImage from "@/components/shop/ShopifyLazyImage";
import ProductTag from "@/components/shop/ProductTag";
import Seal from "@/components/global/Seal";
import BrandLink from "@/components/shop/BrandLink";
import PrismicLink from "@/components/prismic/PrismicLink";
import Icons from "@/components/shop/Icons";
import ProductSizePicker from "@/components/shop/ProductSizePicker";
import ProductShadePicker from "@/components/shop/ProductShadePicker";
import Frequency from "@/components/shop/Frequency";
import AddToBag from "@/components/shop/AddToBag";
import Button from "@/components/global/Button";
import ArrowLink from "@/components/global/ArrowLink";
import CopyLink from "@/components/global/CopyLink";

export default {
  name: "QuickShopProduct",

  mixins: [productMixin],

  components: {
    ShopifyLazyImage,
    ProductTag,
    Seal,
    BrandLink,
    PrismicLink,
    Icons,
    ProductSizePicker,
    ProductShadePicker,
    Frequency,
    AddToBag,
    Button,
    ArrowLink,
    CopyLink
  },

  props: {
    product: {
      type: Object,
      default: null
    },
    quickShopVariantId: {
      type: [Number, String],
      default: null
    }
  },

  methods: {
    ...mapActions([
      'openHeaderMenuExpand'
    ]),
  },


  computed: {
    ...mapState({
      locale: state => state.locale,
    }),
    ...mapGetters(["domain",'isLoggedIn']),
    uid() {
      return this.product._meta.uid;
    },
    selectedVariantLink() {
      if (!this.selectedVariant) return null;

      return this.quickShopVariantId
        ? `/shop/product/${this.uid}?variant=${this.cleanVariantId(
            this.selectedVariant.id
          )}`
        : null;
    },
    copyLink() {
      if (!this.product) return null;

      return this.domain + (this.selectedVariantLink
        ? this.selectedVariantLink
        : `/shop/product/${this.uid}`);
    }
  },

  async created() {
    await this.fetchProductData(this.uid);
    await this.initShopping();

    this.fetchShopCategory();
  }
};
</script>
