<template>
  <fieldset v-if="prices && value" class="frequency">
    <legend class="accessible">Frequency</legend>
    <input
      id="once"
      type="radio"
      value="once"
      :name="'frequency' + (type ? '-' + type : '')"
      v-model="frequency.selected" />
    <label for="once">
      <span>Buy Once</span>
      <Price :price="prices.once" :salePrice="salePrices.once" />
    </label>
    <input
      id="subscription"
      type="radio"
      value="subscription"
      :name="'frequency' + (type ? '-' + type : '')"
      v-model="frequency.selected" />
    <label for="subscription">
      <span>
        Every
        <AutoWidthSelect
          id="frequency"
          :options="frequencies"
          v-model="frequency.subscriptionFrequency" />
      </span>
      <Price
        :price="prices.subscription"
        :salePrice="salePrices.subscription" />
    </label>
  </fieldset>
</template>

<script>
  import { getFrequencyOptions } from '@/helpers'

  import AutoWidthSelect from '@/components/global/AutoWidthSelect'
  import Price from '@/components/shop/Price'

  export default {
    name: 'Frequency',

    components: {
      AutoWidthSelect,
      Price,
    },

    props: {
      type: {
        type: [Number, String],
        default: '',
      },
      prices: {
        type: Object,
        default: null,
      },
      salePrices: {
        type: Object,
        default: null,
      },
      value: {
        type: Object,
        default: {},
      },
      frequencies: {
        type: Object,
        default: null,
      },
    },

    computed: {
      frequency: {
        get() {
          return this.value
        },
        set(value) {
          console.log(value)
          this.$emit('input', value)
        },
      },
    },

    methods: {
      getFrequencyOptions,
    },
  }
</script>
