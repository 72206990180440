<template>
  <address>
    <template v-if="address.firstName || address.lastName">
      {{ address.firstName }} {{ address.lastName }}<br />
    </template>
    {{ address.address1 }}<br />
    <template v-if="address.address2">
      {{ address.address2 }}<br />
    </template>
    {{ address.city }}, {{ address.provinceCode}} {{ address.zip }}<br />
    {{ address.country }}<br />
    {{ address.phone }}
  </address>
</template>

<script>
  export default {
    name: 'Address',

    props: {
      address: {
        type: Object,
        default: null
      }
    }
  }
</script>
